import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h1 style={{ textAlign: "center", width: "100%" }}>404 NOT FOUND</h1>
    <Link to="/" style={{ textAlign: "center", width: "100%", "color": "gray" }}>
      Go to home page
    </Link>
  </Layout>
)

export default NotFoundPage
